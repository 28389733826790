@import 'fonts';

// Body font definition
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    src: url('./font/Inter-3.18/Inter-Regular.woff2'), url('./font/Inter-3.18/Inter-Regular.woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: $font-weight-semibold;
    src: url('./font/Inter-3.18/Inter-SemiBold.woff2'), url('./font/Inter-3.18/Inter-SemiBold.woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    src: url('./font/Inter-3.18/Inter-Bold.woff2'), url('./font/Inter-3.18/Inter-Bold.woff');
}

@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: normal;
    src: url('./font/Inter-3.18/Inter-Italic.woff2'), url('./font/Inter-3.18/Inter-Italic.woff');
}

@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: $font-weight-semibold;
    src: url('./font/Inter-3.18/Inter-SemiBoldItalic.woff2'), url('./font/Inter-3.18/Inter-SemiBoldItalic.woff');
}

// Title font definition
@font-face {
    font-family: 'Tiempos';
    font-weight: bold;
    src: url('./font/v2/TiemposTextWeb-Medium.woff2'), url('./font/v2/TiemposTextWeb-Medium.woff'),
        url('./font/v2/TiemposTextWeb-Medium.eot');
}

@font-face {
    font-family: 'Tiempos-semibold';
    font-weight: bold;
    src: url('./font/v2/TiemposTextWeb-Semibold.woff2'), url('./font/v2/TiemposTextWeb-Semibold.woff'),
        url('./font/v2/TiemposTextWeb-Semibold.eot');
}

// Monospace font definition
@font-face {
    font-family: 'RobotoMono';
    font-weight: normal;
    src: url('./font/v2/Roboto-Mono-Regular.woff2'), url('./font/v2/Roboto-Mono-Regular.woff');
}
